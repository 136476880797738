import { FetchPolicy, ApolloQueryResult, DocumentNode } from '@apollo/client';
import client from '../Api/ApiClient';
export const executeQuery = async <T>(query: DocumentNode, fetchPolicy: FetchPolicy = 'network-only'): Promise<ApolloQueryResult<T>> => {
    return new Promise<ApolloQueryResult<T>>((resolve, reject) => {
        const observable = client.watchQuery<T>({
            query,
            fetchPolicy,
        });
        const subscription = observable.subscribe({
            next: resolve,
            error: reject,
        });
        return () => subscription.unsubscribe();
    });
};
